@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
:root {
  --toastify-icon-color-error: white;
}

.Toastify__toast-container {
  width: auto;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 100vw;
    text-align: center;
  }
}

.Toastify__toast-icon {
  margin-inline-end: unset;
  margin-right: 16px;
}

* {
  font-family: 'Inter', sans-serif;
}

html {
  min-width: 320px;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

input:focus,
button:focus {
  outline: none;
}

input[type='checkbox'],
input[type='checkbox']:checked {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

input[type='number']:focus-visible + span {
  background-color: #ff9903;
}

input[type='number']:focus-visible::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ff9903;
}

input[type='number']:focus-visible:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #ff9903;
}

input[type='number']:focus-visible::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #ff9903;
}

input[type='number']:valid + span {
  display: none;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .markdown h1 {
    @apply text-3xl font-bold;
  }

  .markdown h2 {
    @apply text-2xl font-bold;
  }

  .markdown h3 {
    @apply text-xl font-bold;
  }

  .markdown h4 {
    @apply text-lg font-bold;
  }

  .markdown a {
    @apply text-blue-600 underline;
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}
::-webkit-scrollbar-thumb {
  background: rgba(204, 204, 204, 0.3);
  border-radius: 4px;
}

::-webkit-scrollbar-track {
  background: #262626;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

.button-gradient-background {
  background: linear-gradient(90deg, rgba(255,153,3,1) 6.37%, rgba(255,134,2,1) 40.09%, rgba(255,114,12,1) 90.62%);
}

.button-gradient-background-no-hover:hover {
  background: #1a1a1a !important;
  border: 1px solid;
}

.button-gradient-background-no-hover {
  background: linear-gradient(90deg, rgba(255,153,3,1) 6.37%, rgba(255,134,2,1) 40.09%, rgba(255,114,12,1) 90.62%) !important;
  border: none !important;
}

.button-gradient-background:hover {
  background: none;
}

.gradient-orange-background {
  background: linear-gradient(90deg, rgba(255,153,3,1) 6.37%, rgba(255,134,2,1) 40.09%, rgba(255,114,12,1) 90.62%) !important;
}
